import React from 'react';
import './Footer.css';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import {
    backgroundImage,
    copernicusAtmosphereLogo,
    copernicusClimateLogo,
    copernicusEmergencyLogo,
    copernicusLandLogo,
    copernicusMarineLogo,
    copernicusSecurityLogo
} from '../../theme/images';
import { RTReduxAction, setGDPR } from '@acri-tools/react-tools/redux/actions';
import { GDPRState } from '@acri-tools/react-tools/redux/reducers/gdprReducer';

import LEGAL_LINK from '../../theme/assets/documents/Legal_Notice_OPS-MDS.pdf';
import TERMS_LINK from '../../theme/assets/documents/OPS-MDS_terms_and_conditions_FR_EN - Copie.pdf';


interface Props {
    // ====== Redux ======
    login?: any
    setGDPR: RTReduxAction<GDPRState>
}

const COPERNICUS_SERVICES = [
    {
        name: "Marine", href: "https://marine.copernicus.eu/", src: copernicusMarineLogo
    },
    {
        name: "Atmosphere", href: "https://atmosphere.copernicus.eu/", src: copernicusAtmosphereLogo
    },
    {
        name: "Security", href: "https://www.copernicus.eu/en/copernicus-services/security", src: copernicusSecurityLogo
    },
    {
        name: "Emergency", href: "https://emergency.copernicus.eu/", src: copernicusEmergencyLogo
    },
    {
        name: "Climate", href: "https://climate.copernicus.eu/", src: copernicusClimateLogo
    },
    {
        name: "Land", href: "https://land.copernicus.eu/", src: copernicusLandLogo
    }
];

export default connect(({ login }: any) => {
    return { login }
}, { setGDPR }
)((props: Props) => {
    if (isMobile) return null;

    return (
        !isMobile &&
        <div id="footer">
            <div className="main-footer" style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: "top" }}>
                <div className="footer-container">
                    <div
                        className="homepage-small-title copernicus-services-title"
                        style={{ color: "white" }}
                    >
                        Copernicus Services
                    </div>
                    <div className='copernicus-logos'>
                        {
                            COPERNICUS_SERVICES.map((service) => (
                                <a key={service.name} className="copernicus-service" href={service.href} target="_blank" rel="noopener noreferrer">
                                    <img className="copernicus-logo" alt={`Copernicus ${service.name} Logo`} src={service.src} />
                                    <div className="copernicus-service-title">{service.name}</div>
                                </a>
                            ))
                        }
                    </div>

                </div>

            </div>
            <div className="sub-footer">
                <div className="rights">
                    <div className="rights-text">
                        © ACRI-ST {new Date().getUTCFullYear()}. All Rights Reserved.
                    </div>
                    <div className="rights-links">
                        <a className='sub-footer-gdpr' href={LEGAL_LINK} target="_blank">Legal Notice</a>
                        <i className="bullet fas fa-circle" />
                        <a className='sub-footer-gdpr' href={TERMS_LINK} target="_blank">General Conditions</a>
                        <i className="bullet fas fa-circle" />
                        <div
                            className='sub-footer-gdpr'
                            onClick={() => {
                                props.setGDPR({ open: true })
                            }}>GDPR</div>
                    </div>
                </div>
            </div>
        </div>
    );
});

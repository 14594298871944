export const logo = require('./CMEMS_white.png');
export const logo_colored = require('./CMEMS_ICL_logo.png');
export const bannerImage = require('./banner.jpg');
export const bannerImageMobile = require('./banner.jpg');

export const homeBanner = require('./home_banner.jpg');
export const serviceBanner = require('./banner.jpg');
export const consortiumBanner = require('./banner.jpg');
export const productionBaselineBanner = require('./banner.jpg');
export const kpiBanner = require('./banner.jpg');
export const actionsTrackingBanner = require('./banner.jpg');
export const documentsBanner = require('./banner.jpg');

export const loginBackground = require('./login.jpg'); //login_3_alt.jpg
export const accountBackground = require('./banner.jpg');
export const contactBackground = require('./contact_banner.jpg');
export const RandomProductBanner = require('./random_product.jpg');
export const LatestProductsBanner = require('./latest_products.jpg');

export const backgroundImage = require('./background.jpg');
export const logos = require('./logos.png');

export const copernicusAtmosphereLogo = require('./copernicus_atmosphere_logo_white.png');
export const copernicusClimateLogo = require('./copernicus_climate_logo_white.png');
export const copernicusEmergencyLogo = require('./copernicus_emergency_logo_white.png');
export const copernicusLandLogo = require('./copernicus_land_logo_white.png');
export const copernicusMarineLogo = require('./copernicus_marine_logo_white.png');
export const copernicusSecurityLogo = require('./copernicus_security_logo_white.png');



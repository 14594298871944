import React, { Component } from 'react';
import './Workflows.css';
import classNames from 'classnames';
import SearchBar from '@acri-tools/react-tools/components/SearchBar/SearchBar';
import { fetchWorkflows, getProperProgress, Workflow } from '../../../../utils/workflow';
import { connect } from 'react-redux';
import { WorkflowsState } from '../../../../redux/reducers/workflowsReducer';
import Loading from '@acri-tools/react-tools/components/Loading/Loading';
import Tooltip from 'react-tooltip';
import WorkflowForm from '../../../../components/WorkflowForm/WorkflowForm';
import Tabs from '@acri-tools/react-tools/components/Tabs/Tabs';
import { ImInfo } from 'react-icons/im';
import { toDashboardSection } from '../../../../utils/dashboard';
import { timeSince } from '@acri-tools/react-tools/utils';
import { RiTimerLine } from 'react-icons/ri';
import { MdSync } from 'react-icons/md';
import { TiTimes } from 'react-icons/ti';

interface Props {
    // redux
    workflows: WorkflowsState
}

interface State {
    search: string
    selectedWorkflow?: Workflow
}


class Workflows extends Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            search: '',
        }
    }
    componentDidMount() {
        this.search();
    }

    search = async () => {
        fetchWorkflows();
    }

    render() {

        return (
            <div className="workflow-sections">
                <div className="workflow-section workflow-search">
                    <div className="operations-bar">
                        <div
                            className='custom-button inverted rounded shadow green'
                            onClick={() => {
                                toDashboardSection('create_workflow')
                            }}
                        >
                            ADD NEW WORKFLOW
                        </div>
                        <div
                            className={classNames({
                                'custom-button inverted rounded shadow blue': true,
                                "disabled": this.props.workflows.loading_workflows
                            })}
                            onClick={() => {
                                if (!this.props.workflows.loading_workflows)
                                    fetchWorkflows()
                                        .then(() => {
                                            if (this.state.selectedWorkflow)
                                                this.setState({
                                                    selectedWorkflow: this.props.workflows.workflows?.find((w) => w.id === this.state.selectedWorkflow?.id)
                                                })
                                        })
                            }}
                        >
                            {
                                this.props.workflows.loading_workflows ?
                                    <Loading message="Loading..." spinnerProps={{ white: true, height: 12 }} />
                                    :
                                    <><MdSync /> REFRESH</>
                            }
                        </div>
                    </div>

                    <SearchBar
                        value={this.state.search}
                        onChange={(search) => { this.setState({ search }) }}
                    />
                    <div className="workflows">
                        <div className="workflow-list scrollbar">
                            {
                                !this.props.workflows.workflows &&
                                    this.props.workflows.loading_workflows ?
                                    <Loading message="Loading workflows..." />
                                    :
                                    this.props.workflows.workflows && this.props.workflows.workflows.length > 0 ?
                                        this.filterWorkflows()?.map((w, idx) => (
                                            <div className="workflow-item" key={idx}>
                                                <div className="workflow-name">
                                                    {w.name}
                                                </div>
                                                {
                                                    w.status &&
                                                    <>
                                                        <div className="workflow-date">
                                                            {w.status.startedAt}
                                                        </div>
                                                        <div className="workflow-date">
                                                            {w.status.finishedAt || "-"}
                                                        </div>
                                                        <div className="workflow-duration">
                                                            {
                                                                w.status.startedAt &&
                                                                w.status.finishedAt &&
                                                                <div className="duration-tag">
                                                                    <RiTimerLine /> {timeSince(w.status.startedAt, w.status.finishedAt)}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className={`workflow-status`}>
                                                            <div className={`status-tag ${w.status.phase}`}>
                                                                {w.status.phase}
                                                            </div>
                                                        </div>
                                                        <div className={`workflow-progress`} data-tip data-for={`tooltip-workflow-progress-${idx}`}>
                                                            {/* { w.status.progress } */}
                                                            {getProperProgress(w)}
                                                        </div>
                                                        <Tooltip
                                                            place="bottom" id={`tooltip-workflow-progress-${idx}`} effect="solid"
                                                            getContent={() => (
                                                                <div className="workflow-task-status-list">
                                                                    {
                                                                        w.tasks.map((t, widx) => (
                                                                            <div className="workflow-task-status" key={widx}>
                                                                                <div className="workflow-task-status-name">
                                                                                    {t.name}
                                                                                </div>
                                                                                <div className={`status-tag ${t.status?.phase}`}>
                                                                                    {t.status?.phase || "Queued"}
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }

                                                                </div>
                                                            )}
                                                        />
                                                    </>
                                                }

                                                <div className={`workflow-operations`}>
                                                    <div
                                                        className={classNames({
                                                            "custom-button tt small blue rounded shadow icon-only": true,
                                                            "inverted": this.state.selectedWorkflow?.id === w.id
                                                        })}
                                                        onClick={() => {
                                                            this.setState({ selectedWorkflow: w });
                                                        }}
                                                    >
                                                        <div className="tt-tooltip">
                                                            View
                                                        </div>
                                                        <i className="fas fa-eye" />
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                        :
                                        <div className="no-data">No workflows</div>
                            }
                        </div>


                    </div>
                </div>

                <div className={classNames({ "workflow-section selected-workflow": true, "hidden": !this.state.selectedWorkflow })}>

                    <div className="workflow-toggle">
                        <div className="workflow-toggle-content" onClick={() => { this.setState({ selectedWorkflow: undefined }) }}>
                            <TiTimes />
                        </div>
                    </div>


                    {
                        this.state.selectedWorkflow &&
                        <div className="workflow-details">
                            <Tabs
                                tabContent={{
                                    className: " scrollbar"
                                }}
                                tabs={[
                                    {
                                        label: <><ImInfo /> Workflow Spec</>,
                                        content: <WorkflowForm workflow={this.state.selectedWorkflow} />
                                    }
                                ]}
                            />
                        </div>
                    }
                </div>

            </div>
        );
    }



    filterWorkflows = () => {
        if (!this.state.search) return this.props.workflows.workflows;
        return this.props.workflows.workflows?.filter((w) =>
            JSON.stringify(w).toLowerCase().includes(this.state.search)
        )
    }

};


export default connect((state: any) => {
    return {
        workflows: state.workflows
    }
})(Workflows);

import React,{Component} from 'react';
import { CreateWorkflow, CreateWorkflowTask, Quotas, validCPUQuota, validMemoryQuota, validMemorySyntax } from '../../utils/workflow';
import './WorkflowTaskForm.css';
import { IoText } from 'react-icons/io5';
import { FaDocker } from 'react-icons/fa';
import InputList from '../InputList/InputList';
// import InputList from '@acri-tools/react-tools/components/InputList/InputList';
import { CgTerminal } from 'react-icons/cg';
import Input, { REQUIRED } from '../Input/Input';
import { TaskInputArtifacts, TaskOutputArtifacts } from '../TaskArtifacts/TaskArtifacts';
import DependsOnTasks from '../DependsOnTasks/DependsOnTasks';
import { Icons } from '../../utils/global';



export interface CreateTaskProps{
    onChange?: (updates:any)=>void
    onInputUpdate?: () => void
    workflow: CreateWorkflow
    task: CreateWorkflowTask
}

interface State{
}


export default class CreateWorkflowTaskForm extends Component<CreateTaskProps, State>{
    render(){
        return (
            <div className="workflow-task-form">
                <div className="row-2">
                    <Input
                        label={<><IoText/> Task Name</>}
                        type="text"
                        update
                        onChange={(name)=>this.onChange({name})}
                        value={this.props.task.name}
                        requirements={[
                            REQUIRED,
                            {
                                message: 'Duplicate task name',
                                validation: (value)=>{ 
                                    return !this.props.workflow.tasks.some((t)=> t !== this.props.task && t.name === value )
                                }
                            },
                            {
                                message: 'Duplicate name with workflow',
                                validation: (value)=>{ 
                                    return !(value === this.props.workflow.name)
                                }
                            }
                        ]}
                    />
                    <Input
                        label={<><FaDocker/> Image</>}
                        type="text"
                        update
                        onChange={(image)=>this.onChange({image})}
                        value={this.props.task.image}
                        requirements={[ REQUIRED ]}
                    />
                </div>

                <div className="row-2">
                    <InputList 
                        type={'text'} 
                        update
                        label={<label><CgTerminal/> Command</label>} 
                        values={this.props.task.command|| []} 
                        onChange={(command)=>this.onChange({ command })}
                    />
                    <InputList 
                        type={'text'} 
                        update
                        label={<label><CgTerminal/> Args</label>} 
                        values={this.props.task.args|| []} 
                        onChange={(args)=>this.onChange({ args })}                        
                    />
                </div>
                <div className="row-2">
                    <TaskInputArtifacts
                        task={this.props.task}
                        workflow={this.props.workflow}
                        values={this.props.task.inputs || []} 
                        onChange={(inputs)=>this.onChange({ inputs })}
                        onInputUpdate={this.props.onInputUpdate}
                    />
                    <TaskOutputArtifacts
                        task={this.props.task}
                        workflow={this.props.workflow}
                        values={this.props.task.outputs || []} 
                        onChange={(outputs)=>this.onChange({ outputs })}  
                        onInputUpdate={this.props.onInputUpdate}
                    />
                </div>
                <div className="row-2">
                    <Input
                        type='number' update
                        requirementsOnValue
                        requirements={[
                            {
                                message: `Minimum value 1`,
                                validation: (value: number)=>{
                                    return value > 0
                                }
                            },
                            {
                                message: `Maximum value ${Quotas.cpu_limit}`,
                                validation: validCPUQuota
                            }
                        ]}
                        label={<label>{ Icons.cpu } CPU Limit</label>} 
                        value={this.props.task.cpu_limit || ''} 
                        onChange={(cpu_limit)=>this.onChange({ cpu_limit })}
                        inputProps={{
                            min: 1,
                            max: Quotas.cpu_limit
                        }}
                    />
                    <Input
                        type='text' update
                        requirementsOnValue
                        requirements={[
                            {
                                message: 'Syntax: <value>Gi or <value>Mi',
                                validation: validMemorySyntax
                            },
                            {
                                message: `Maximum value ${Quotas.memory_limit}Gi`,
                                validation: validMemoryQuota
                            },
                            // {
                            //     message: 'Syntax: <value>Gi or <value>Mi',
                            //     validation: (value:string)=>{ 
                            //         return !value || (/^[0-9]*(Mi|Gi)$/).test(value)
                            //     }
                            // }
                        ]}
                        label={<label>{ Icons.memory } Memory Limit</label>} 
                        value={this.props.task.memory_limit || ''} 
                        onChange={(memory_limit)=>this.onChange({ memory_limit })}
                    />
                </div>
                <div className="row-2">
                    <DependsOnTasks
                        values={this.props.task.depends_on_task_names || []}
                        workflow={this.props.workflow}
                        task={this.props.task}
                        onChange={(depends_on_task_names)=>{ this.onChange({ depends_on_task_names }) }}
                    />
                </div>
            </div>
        );
    }

    onChange = (updates: any) =>{
        this.props.onChange?.(updates)
    }
}


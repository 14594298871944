import React, { Component } from 'react';
import './BucketTaskForm.css';
import { CreateTaskProps } from './CreateWorkflowTaskForm';
import { TbSatellite } from 'react-icons/tb';
import { FaPlus, FaCheck } from 'react-icons/fa';
import { IoText } from 'react-icons/io5';
import Modal from '@acri-tools/react-tools/components/Modal/Modal';
import BucketList, { BucketSelectedProducts } from '../BucketList/BucketList';
import Input from '../Input/Input';
import { TaskOutputArtifactsValues } from '../TaskArtifacts/TaskArtifactsValues';

interface State {
    modalOpen: boolean
}


export default class BucketTaskForm extends Component<CreateTaskProps, State>{
    constructor(props: CreateTaskProps) {
        super(props);
        this.state = {
            modalOpen: false,
        }
    }
    add = () => {
        this.setState({ modalOpen: true })
    }
    render() {
        return (
            <>
                <Modal
                    className='bucket-task-modal'
                    opened={this.state.modalOpen}
                    close={() => { this.setState({ modalOpen: false }) }}
                >
                    {/* <div className="modal-title">
                        SMALL MODAL
                        <ModalClose close={()=>{ this.setState({ modalOpen : false }) }}/>
                    </div> */}
                    <div className="modal-content">
                        <BucketList
                            selected={this.props.task?.s3Inputs}
                            onChange={(s3Inputs) => { this.onChange({ s3Inputs }) }}
                        />

                        <div className="centered">
                            <div className="custom-button small rounded shadow blue" onClick={() => { this.setState({ modalOpen: false }) }}>
                                <FaCheck /> APPLY
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="workflow-task-form bucket-task-form">
                    <div className="row-2">
                        <Input
                            label={<><IoText/> Task Name</>}
                            type="text"
                            value={this.props.task.name}
                        />
                        <TaskOutputArtifactsValues
                            // task={this.props.task}
                            // workflow={this.props.workflow}
                            values={this.props.task.outputs || []} 
                        />
                    </div>
                    
                    <div className="form-field">
                        <div className="bucket-task-bar">
                            <label><TbSatellite /> Products</label>
                            <div
                                className="bucket-task-add"
                                onClick={this.add}
                            >
                                <FaPlus /> SELECT
                            </div>
                        </div>
                    </div>
                    <div className="bucket-task-values">
                        <BucketSelectedProducts
                            selected={this.props.task?.s3Inputs}
                            onChange={(s3Inputs) => { this.onChange({ s3Inputs }) }}
                        />
                    </div>
                </div>
            </>
        );
    }

    onChange = (updates: any) => {
        this.props.onChange?.(updates)
    }
}


import React, { useEffect } from 'react';

import './TopBarHeader.css';
import { COPERNICUS_MARINE_SERVICE_URL } from '../../utils/global';
import { logos } from '../../theme/images';
import { connect } from 'react-redux';
import { RTReduxAction, setLayout } from '@acri-tools/react-tools/redux/actions';
import { LayoutState } from '@acri-tools/react-tools/redux/reducers/layoutReducer';


interface Props {
    layout: any
    setLayout: RTReduxAction<LayoutState>
}


export default connect(({ layout }: any) => {
    return { layout }
}, { setLayout })((props: Props) => {

    useEffect(() => {
        if (props.layout.scrollY > 200) {
            props.setLayout({ scrollY: 0 });
        }
    }, []);


    return (
        <>
            {
                props.layout.scrollY <= 200 &&
                <div className='top-bar-header' style={{ marginTop: `-${props.layout.scrollY}px` }}>
                    <div className='top-bar-header-small-container'>
                        <img className='multiselect-tooltip-image' src={logos} />
                        <a href={COPERNICUS_MARINE_SERVICE_URL} target="_blank" >
                            <div className='mds-go-back-button'>
                                <i className="fas fa-arrow-right fa-sm" />
                                Return to Copernicus Marine Service website
                            </div>
                        </a>
                    </div>

                    <div className='mds-header-title'>Interactive Computing Layer</div>

                </div>
            }
        </>
    );
});
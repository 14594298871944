import { axiosErrorHandler } from "@acri-tools/react-tools/utils"
import store from "../redux"
import { setWorkflows } from "../redux/actions"
import { API_URL } from "./global"
import request from "./request"


// WORKFLOW FROM API
export const Quotas = {
    cpu_limit: process.env["REACT_APP_CPU_LIMIT"] ? parseInt(process.env["REACT_APP_CPU_LIMIT"]) : 4,
    memory_limit: process.env["REACT_APP_MEMORY_LIMIT"] ? parseInt(process.env["REACT_APP_MEMORY_LIMIT"]) : 4,
}


export type WorkflowArtifact = {
    [workflowId: string]: {
        [taskId: string]: string[]
        // [taskId: string]: TaskArtifact[]
    }
} 
export type TaskArtifact = {
    name: string
    id: string
    boundaryId: string
} 

export type Workflow = {
    id?: string
    name: string
    tasks: WorkflowTask[]
    status?: {
        phase?: string
        progress?: string
        startedAt?: string
        finishedAt?: string
    }
}

export type S3Artifact = {
    key: string
}
export type ArtifactInput = {
    name: string
    path: string
    from_task?: string
    s3?: S3Artifact
}
export type ArtifactOutput = {
    name: string
    path: string
}
export type WorkflowTask = {
    id: string
    name: string
    image: string
    command?: string[]
    args?: string[]
    inputs: ArtifactInput[]
    outputs: ArtifactOutput[]
    depends_on_task_names?: string[]
    cpu_limit?: number
    memory_limit?: string
    status?: {
        message?: string
        phase?: string
        progress?: string
        startedAt?: string
        finishedAt?: string
    }
}

// WORKFLOW CREATION ON APP
export type CreateWorkflow = {
    name: string
    tasks: CreateWorkflowTask[]
}

export type CreateArtifactOutput = {
    name: string
    path: string
    keep_artifact: boolean
}
export type CreateArtifactInput = {
    task: CreateWorkflowTask
    output: CreateArtifactOutput
    path: string
}
export type CreateWorkflowTask = {
    template?: string
    name: string
    image: string
    command: string[]
    args: string[]
    inputs: CreateArtifactInput[]
    outputs: CreateArtifactOutput[]
    depends_on_task_names: CreateWorkflowTask[]
    s3Inputs?: string[]
    cpu_limit?: number
    memory_limit?: string
}

// WHAT TO POST TO THE API
export type PostWorkflow = {
    name: string
    tasks: PostWorkflowTask[]
}

export type PostArtifactOutput = {
    name: string
    path: string
    keep_artifact?: boolean
}
export type PostArtifactInput = {
    name: string
    path: string
    from_task?: string
    key?: string
}
export type PostWorkflowTask = {
    name: string
    image: string
    command?: string[]
    args?: string[]
    inputs?: PostArtifactInput[]
    outputs?: PostArtifactOutput[]
    depends_on_task_names?: string[]
    cpu_limit?: number
    memory_limit?: string
}


export const workflowRegex = {
    name: new RegExp(/^[a-z\-0-9]*$/)
}

export const getProperProgress = (workflow: Workflow) =>{
    return `${workflow.status?.progress?.split('/')?.[0] || "0"}/${workflow.tasks.length}`
}



export const getDefaultWorkflowTask = (): CreateWorkflowTask => {
    return {
        name: '',
        image: '',
        command: [],
        args: [],
        depends_on_task_names: [],
        inputs: [],
        outputs: []
    }
}


export const validOutput = (output:ArtifactOutput) =>{
    return output.name && output.path;
}
export const validInput = (input: CreateArtifactInput) =>{
    return input.path;
}

export const MemoryLimitRegex = /^([0-9]|([0-9]+\.[0-9]+))+(Mi|Gi)$/;
export const validMemorySyntax = (value: CreateWorkflowTask["memory_limit"]) =>{
    return !value || (MemoryLimitRegex).test(value)
}
export const validMemoryQuota = (value: CreateWorkflowTask["memory_limit"]) =>{
    try{
        if(!value) return false;
        if(!(MemoryLimitRegex).test(value)) return false;
        let split = value?.split(/(Gi|Mi)/);
        if(split.length === 3){
            if(split[0] === '') return false;
            let v = parseInt(split[0]);
            if(split[1] === 'Mi') v = v/1024;
            return v <= Quotas.memory_limit;
        }
        else return false;
    }
    catch(e){
        return false;   
    }
}
export const validMemoryValue = (task: CreateWorkflowTask) =>{
    return !task.memory_limit || (validMemorySyntax(task.memory_limit) && validMemoryQuota(task.memory_limit));
}

export const validCPUQuota = (value: CreateWorkflowTask["cpu_limit"]) =>{
    return !value || value <= Quotas.cpu_limit;
}
export const validCPUValue = (task: CreateWorkflowTask) =>{
    return validCPUQuota(task.cpu_limit);
}



export const isValidTask = (task: CreateWorkflowTask, workflow: CreateWorkflow) =>{
    return task.image !== '' 
        && task.name !== '' 
        && task.name !== workflow.name
        && validMemoryValue(task)
        && validCPUValue(task)
        && task.outputs.every((output)=> validOutput(output))
        && task.inputs.every((input)=> validInput(input))
        && !workflow.tasks.some((t)=> t !== task && task.name === t.name)
        
        // && task.depends_on_task_names?.every((dep)=>
        //     workflow.tasks.map((t)=> t.name ).includes(`${dep}`)
        // );
}

export const fetchWorkflows = async () =>{
    store.dispatch(setWorkflows({ loading_workflows: true }))
    try{
        let workflowReq = request(`${API_URL}/v1/workflow/`,{
            method: 'get'
        }).then((workflowRes)=>{

            let workflows: Workflow[] = (workflowRes?.data || []).map(formatWorkflow).filter((w:any)=> w !== undefined )
            console.log("workflows", workflows)
            store.dispatch(setWorkflows({
                workflows,
            }));
        })

        let artifactsReq = request(`${API_URL}/v1/artifact/`,{
            method: 'get'
        }).then((artifactsRes)=>{
            console.log("artifacts", artifactsRes?.data)
            store.dispatch(setWorkflows({
                artifacts: artifactsRes?.data || []
            }));
        })

        await Promise.all([workflowReq, artifactsReq]);

        runIntervalListener();
    }
    catch(e){
        axiosErrorHandler(e, { functionName: 'search', displayError: true });
    }
    finally{
        store.dispatch(setWorkflows({ loading_workflows: false }))
    }
}

const getTaskId = (node: any) =>{
    let name = node.id.split('-');
    name.splice(name.length - 1, 0, node.displayName);
    return name.join('-');
}

export const formatWorkflow = (d:any): Workflow|undefined => {
    try{

        let templates: any[] = d.spec.templates;
        let task_dependencies = templates.splice( templates.findIndex((t)=> t.name === d.metadata.generate_name), 1)[0];
        let task_nodes:any[] = Object.values(d.status.nodes);
        return {
            id: d.metadata.name,
            name: d.metadata.generate_name || d.metadata.generateName,
            status: {
                phase: d.status.phase,
                progress: d.status.progress,
                startedAt: d.status.startedAt || d.status.started_at,
                finishedAt: d.status.finishedAt || d.status.finished_at,
            },
            tasks: d.spec.templates.filter((t:any)=> !(t.name === d.metadata.generate_name || t.name === d.metadata.generateName) ).map((t: any):WorkflowTask=>{
                let dependency_index = task_dependencies.dag.tasks.findIndex((dt:any)=> t.name === dt.name  );
                let task_status:any = task_nodes.find((n: any)=> n.template_name === t.name || n.templateName === t.name);
                let inputs = task_status?.inputs?.artifacts;
                let outputs = task_status?.outputs?.artifacts;
                inputs?.forEach((i: any) => {
                    let artifact_from = i?.['s3']?.key.split('/')[1];
                    i.from_task = (task_nodes.find((n: any)=> artifact_from === getTaskId(n)))?.displayName;
                })

                return {
                    id: task_status?.id,
                    name: t.name,
                    image: t.container.image,
                    command: t.container.command,
                    args: t.container.args,
                    depends_on_task_names: dependency_index === -1 ? undefined : task_dependencies.dag.tasks[dependency_index].depends?.split(" && "),
                    status: {
                        message: task_status?.message,
                        phase: task_status?.phase,
                        progress: task_status?.progress,
                        startedAt: task_status?.startedAt || task_status?.started_at,
                        finishedAt: task_status?.finishedAt || task_status?.finished_at,
                    },
                    inputs,
                    outputs,
                    cpu_limit: t.container.resources?.limits?.cpu,
                    memory_limit: t.container.resources?.limits?.memory,
                }
            })
        }
    }
    catch(e){
        return undefined;
    }
}

// let running_interval:any;

const runIntervalListener = () =>{
    return;
    // if(running_interval) clearInterval(running_interval);
    // running_interval = setInterval(()=>{
    //     let { workflows } = store.getState().workflows;
    //     if(!workflows) return;
    //     for(let workflow of workflows){
    //         if(!["Failed", "Succeeded"].includes(workflow.status?.phase)){
    //             request(`${API_URL}/v1/workflow/${workflow.id}`,{
    //                 method: 'get'
    //             }).then((res)=>{    
    //                 let w: Workflow = formatWorkflow(res.data)
    //                 Object.assign(workflow, w);
    //                 store.dispatch(setWorkflows({ workflows }))
    //             })
    //             .catch(e=> console.error(e))
    //         }
    //     }
    // }, 3000)
}
import React from "react";
import { SiJupyter } from "react-icons/si";
import { HiOutlineCpuChip } from 'react-icons/hi2';
import { FaMemory } from "react-icons/fa";

export const CAS_URL = "https://cmems-cas.cls.fr/";
export const COPERNICUS_MARINE_SERVICE_URL = "https://marine.copernicus.eu/";
export const API_URL = process.env.REACT_APP_BUILD === "true" ? 
    "/api" : 
    "http://localhost:8085";

export const ICONS = {
    "home": "fas fa-home",
    "dashboard": "fas fa-desktop",
    "jupyterhub": <SiJupyter/>,
    "binderhub": "fas fa-code-branch",
    "contact": "fas fa-envelope",
    "documentation": "fas fa-book",
}


export const JUPYTER_URL = process.env.REACT_APP_BUILD === "true" ? 
    "https://icl-jupyterhub.marine.copernicus.eu" :
    "http://localhost:8082";

export const BINDER_URL = process.env.REACT_APP_BUILD === "true" ? 
    "https://icl-binder.marine.copernicus.eu" :
    "http://localhost:8100";


export const Icons = {
    cpu: <HiOutlineCpuChip/>,
    memory: <FaMemory/>
}
import React,{Component, ReactNode} from 'react';

import './Dashboard.css';

import Page from '@acri-tools/react-tools/components/Page/Page';
import { VscGitPullRequestCreate, VscListSelection } from 'react-icons/vsc' 
import { CgMenuGridO } from "react-icons/cg"
import classNames from 'classnames';
import Workflows from './content/Workflows/Workflows';
import CreateWorkflow from './content/CreateWorkflow/CreateWorkflow';
import { match } from 'react-router';
import { toDashboardSection } from '../../utils/dashboard';
import { DiBitbucket } from 'react-icons/di';
import Bucket from './content/Bucket/Bucket';

type Section = {
    name: string,
    icon: ReactNode,
    content: ReactNode
}

interface Props{
    match: match<{section?: string}>
}
interface State{
    toggled: boolean,
    selected_section?: Section
}


class Dashboard extends Component<Props,State>{
    SECTIONS:{
        [section:string]: Section
    } = {
        my_workflows: {
            name: "My Workflows",
            icon: <VscListSelection/>,
            content: <Workflows/>
        },
        create_workflow: {
            name: "Create Workflow",
            icon: <VscGitPullRequestCreate/>,
            content: <CreateWorkflow />
        },
        bucket: {
            name: "MDL Bucket",
            icon: <DiBitbucket/>,
            content: <Bucket/>
        }
    };

    constructor(props:Props){
        super(props);
        this.state={
            toggled: true,
            // selected_section: this.initSelectedSection()
        }
    }
    // initSelectedSection = () =>{
    //     return this.SECTIONS.create_workflow;
    //     // return this.SECTIONS.my_workflows;
    // }
    componentDidMount(){
        this.initSection()
    }
    initSection(){
        if(
            this.props.match.params.section
            && this.props.match.params.section in this.SECTIONS
        ){
            this.setState({ selected_section: this.SECTIONS[this.props.match.params.section] })
        }
        else{
            toDashboardSection(Object.keys(this.SECTIONS)[0]);
            // replaceHistory(`/dashboard/${Object.keys(this.SECTIONS)[0]}`)
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.match.params.section !== this.props.match.params.section){
            this.initSection();
        }
    }
    


    render(){
        return (
        <Page 
            // footer
            id="dashboard"
            topBar={{
                permanentBackground: true
            }}
        >
            <div className="page-content">
                <div
                    className={classNames({
                        "dashboard-menu": true,
                        "toggled": this.state.toggled
                    })}
                >
                    <div
                        className="dashboard-toggle"
                        onClick={()=>{ this.setState({ toggled: !this.state.toggled }) }}
                    >
                        <CgMenuGridO/>
                    </div>

                    {
                        this.state.toggled &&
                        <h2>Dashboard</h2>
                    }
                    <div className="dashboard-menu-list">
                        {
                            Object.entries(this.SECTIONS).map(([key, s], idx)=>(
                                <div 
                                    key={idx}
                                    className={classNames({
                                        "list-item": true,
                                        "selected": this.state.selected_section === s
                                    })}
                                    onClick={()=>{ 
                                        // this.setState({ selected_section: s })
                                        toDashboardSection(key);
                                    }}
                                >
                                    <div className="list-item-icon">{s.icon}</div>
                                    {
                                        this.state.toggled &&
                                        <div className="list-item-name">{s.name}</div>
                                    }
                                </div>
                            ))   
                        }
                    </div>
                </div>
                <div
                    className="dashboard-content"
                >
                    {
                        this.state.selected_section?.content &&
                        <>
                            <h2>{this.state.selected_section?.name}</h2>
                            {
                                this.state.selected_section?.content
                            }
                        </>
                    }
                </div>
            </div>
        </Page>
        );
    }

};


export default Dashboard;

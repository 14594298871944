import React,{Component} from 'react';
import './WorkflowTaskForm.css';
import { Workflow, WorkflowTask } from '../../utils/workflow';
import { IoText } from 'react-icons/io5';
import { FaDocker } from 'react-icons/fa';
import InputList from '../InputList/InputList';
import { CgTerminal } from 'react-icons/cg';
import Input from '../Input/Input';
import DependsOnTasksValues from '../DependsOnTasks/DependsOnTasksValue';
import { TaskInputArtifactsValues, TaskOutputArtifactsValues } from '../TaskArtifacts/TaskArtifactsValues';
import { Icons } from '../../utils/global';

interface Props{
    workflow: Workflow
    task: WorkflowTask
}

interface State{
}


export default class WorkflowTaskForm extends Component<Props, State>{
    render(){
        return (
            <div className="workflow-task-form">
                <div className="row-2">
                    <Input
                        label={<><IoText/> Task Name</>}
                        type="text"
                        value={this.props.task.name}
                    />
                    <Input
                        label={<><FaDocker/> Image</>}
                        type="text"
                        value={this.props.task.image}
                    />
                </div>

                <div className="row-2">
                    <InputList 
                        type={'text'} 
                        label={<label><CgTerminal/> Command</label>} 
                        values={this.props.task.command|| []}
                    />
                    <InputList 
                        type={'text'} 
                        label={<label><CgTerminal/> Args</label>} 
                        values={this.props.task.args|| []}
                    />
                </div>
                <div className="row-2">
                    <TaskInputArtifactsValues
                        task={this.props.task}
                        workflow={this.props.workflow}
                        values={this.props.task.inputs || []} 
                    />
                    <TaskOutputArtifactsValues
                        task={this.props.task}
                        workflow={this.props.workflow}
                        values={this.props.task.outputs || []} 
                    />
                </div>
                {
                    this.props.task.cpu_limit && this.props.task.memory_limit &&
                    <div className="row-2">
                        <Input
                            label={<>{ Icons.cpu } CPU Limit</>}
                            type="text"
                            value={this.props.task.cpu_limit}
                        />
                        <Input
                            label={<>{ Icons.memory } Memory Limit</>}
                            type="text"
                            value={this.props.task.memory_limit}
                        />
                    </div>
                }
                <div className="row-2">
                    <DependsOnTasksValues
                        values={this.props.task.depends_on_task_names || []}
                        workflow={this.props.workflow}
                        task={this.props.task}
                    />
                </div>
            </div>
        );
    }
}

